import useGet from "api/useGet";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, MainVideo, NumberBox, PageSizing, PageTitle } from "component/app/items";
import socketio from "socket.io-client"
import Echo from "laravel-echo";

const Cafe = (props) => {
    const navigate = useNavigate();

    const [type,setType] = useState(0);
    const [nickName,setNickName] = useState(null);
    const [number,setNumber] = useState(null);

    const [dataUpdate,setDataUpdate] = useState(1);

    const monitorApi = useGet({
        url:dataUpdate ? `/monitor?update=${dataUpdate}` : null,
        loginType:"login"
    });
    const monitorData = monitorApi?.data;

    useEffect(() => {
        if(monitorData){
            setNumber(monitorData?.color ? monitorData?.car_number : null);
            setNickName(monitorData?.color ? monitorData?.nickname : null);
            setType(monitorData?.color === "red" ? 1 : 0);
        }
    }, [monitorApi]);

    useEffect(() => {
        if(!window.Echo){
            window.Echo = new Echo({
                host: process.env.REACT_APP_SOCKET_URL, 
                broadcaster: "socket.io",
                client: socketio
            });
        }

        window.Echo.channel("laravel_database_complete").listen("CompletePushed", (e) => {
            if(e.type === "end"){
                setDataUpdate(dataUpdate + 1);
                setTimeout(() => {
                    setDataUpdate(null);
                }, 100);
            }
        });
    }, []);

    return (
        <PageSizing addClass="headerPage">
            <ContentsSection addClass={type == 0 ? "fullPage" : ""}>
                {type == 0 ? 
                    <MainVideo src={"/assets/images/basic/basic_video.mp4"}/>
                : type == 1 ? 
                    <NumberBox
                        bg={null}
                        number={number}
                    />
                :""
                }
                {type !== 0 ? 
                <PageTitle contents={`${nickName||"고객"} 님 세차가 완료되었습니다<br/><span class='drTextColor'>실내세차 존</span>으로 이동해 주세요`}/>
                :""}
            </ContentsSection>
        </PageSizing>
    );
};

export default Cafe;