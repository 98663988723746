function PageSizing(data){
    return (
        <div className={`pageSizing ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function ContentsSection(data){
    return (
        <div className={`contentsSection ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function PageTitle(data){
    return (
        <div className="pageTitleBox">
            <h1 className="pageTitle" dangerouslySetInnerHTML={{__html:data.contents}}/>
        </div>
    );
}

function NumberBox(data){
    return (
        <div className="numberArea">
            {data.bg ? <div className={`numberBgBox ${data.bg ? data.bg : ""}`}></div> : ""}
            <div className="numberBox">
                <h1 className="numberItem" dangerouslySetInnerHTML={{__html:data.number}}/>
            </div>
        </div>
    );
}

function MainVideo(data){
    return (
        <div className={`mainVideoBox`}>
            <video muted playsInline autoPlay loop width="100%" height="auto">
				<source src={data.src} type="video/mp4"/>
			</video>
        </div>
    ); 
}

//버튼 박스
function BtnBox(data){
    return (
        <div className={`${data.boxType == "fixed" ? "pageBtn_box" : "btn_box"} ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

//버튼
function BtnItem(data){
    return (
        <>
        {data.btnType == "link" ? 
        <a href={data.btnLink} target="_blank" className={`pageBtn ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}></a>
        :
        <button type="button" className={`pageBtn ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}></button>
        }
        </>
    );
}

export {PageSizing,ContentsSection,PageTitle,NumberBox,MainVideo,BtnBox,BtnItem};