import { BtnBox, BtnItem } from "component/app/items";
import { PageErrBox } from "component/app/pageErr";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PublicOnly = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if(!localStorage.getItem("token") && window.location.pathname !== "/pageErr"){
            // navigate(0);
        }
    }, []);

    return (
        <div className="pageSizing">
            <PageErrBox
                icon={false}
                title="서비스 이용에 불편을 드려 죄송합니다."
                text="요청하신 페이지가 없거나, 오류가 발생했습니다.<br/>잠시 후 다시 확인해주세요."
            />
            <BtnBox
                boxType="fixed"
                addClass="col2"
            >
                <BtnItem
                    addClass=""
                    contents={"안내화면으로"}
                    disabled={false}
                    func={() => {navigate("/main")}}
                />
                <BtnItem
                    addClass=""
                    contents={"카페테리아로"}
                    disabled={false}
                    func={() => {navigate("/cafe")}}
                />
            </BtnBox>
        </div>
    );
};

export default PublicOnly;