import useGet from "api/useGet";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, NumberBox, PageSizing, PageTitle } from "component/app/items";
import socketio from "socket.io-client"
import Echo from "laravel-echo";

const Main = (props) => {
    const navigate = useNavigate();

    const [type,setType] =  useState(0);
    const [nickName,setNickName] = useState(null);
    const [number,setNumber] = useState(null);

    const [dataUpdate,setDataUpdate] = useState(1);

    const monitorApi = useGet({
        url:dataUpdate ? `/monitor?update=${dataUpdate}` : null,
        loginType:"login"
    });
    const monitorData = monitorApi?.data;

    useEffect(() => {
        if(monitorData){
            setNumber(monitorData?.color ? monitorData?.car_number : null);
            setNickName(monitorData?.color ? monitorData?.nickname : null);
            setType(monitorData?.color ? monitorData?.color === "red" ? 2 : 1 : 0);
        }
    }, [monitorApi]);

    useEffect(() => {
        if(!window.Echo){
            window.Echo = new Echo({
                host: process.env.REACT_APP_SOCKET_URL, 
                broadcaster: "socket.io",
                client: socketio
            });
        }

        window.Echo.channel("laravel_database_complete").listen("CompletePushed", (e) => {
            if(e.type === "end"){
                setDataUpdate(dataUpdate + 1);
                setTimeout(() => {
                    setDataUpdate(null);
                }, 100);
            }
        });
    }, []);

    return (
        <PageSizing addClass="headerPage">
            <ContentsSection addClass="">
                {type == 0 ? 
                    <img src="/assets/images/basic/basic_img.png" className="basicImg"/>
                : type == 1 || type == 2 ? 
                    <NumberBox
                        bg={type == 1 ? "bBg" : "rBg"}
                        number={number}
                    />
                :""
                }
                <PageTitle contents={
                    type == 0 ? "기어 중립을 유지해 주세요"
                    : `${nickName||"고객"} 님<br/>${type == 1 ? `<span class='bTextColor'>파란색 라인</span>` : `<span class='rTextColor'>빨간색 라인</span>`}을 따라 이동해주세요`
                }/>
            </ContentsSection>
        </PageSizing>
    );
};

export default Main;