import PublicOnly from "pages/private/publicOnly";
import React, { useEffect, useState } from "react";
import * as fatchSet from "../api/api";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "component/basic/scrollTopSet";
import { ErrorBoundary } from "react-error-boundary";
import Main from "pages/main/Main";
import Cafe from "pages/main/Cafe";

const Router = () => {
    const [token,setToken] = useState(localStorage.getItem("token") ? true : false);

    window.logIn = function(){
        setToken(true);
    }
    window.logOut = function(){
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        setToken(false);
    }

    useEffect(()=>{
        
    },[]);

    //로그인,비로그인 무관 페이지
    const publicRoutes = [
        {
            exact: true,
            path: "/pageErr",
            component: <PublicOnly/>,
        },
        {
            exact: true,
            path: "/",
            component: <Navigate to="/main" />,
        },
        {
            exact: true,
            path: "/main",
            component: <Main/>,
        },
        {
            exact: true,
            path: "/cafe",
            component: <Cafe/>,
        },
    ];

    //백화 시 노출
    const ErrorFallback = (err) => {
        console.log(err)
        return (
            <div className="pageSizing">
                <div className="pageErrBox">
                    <h1 className="pageErrTitle">서비스 이용에 불편을 드려 죄송합니다.</h1>
                    <p className="pageErrText">요청하신 페이지가 없거나, 오류가 발생했습니다.<br/>잠시 후 다시 확인해주세요</p>
                </div>
                <div className="pageBtn_box col2">
                    <button type="button" className="pageBtn" onClick={() => window.location.href = "/main"}>안내화면으로</button>
                    <button type="button" className="pageBtn" onClick={() => window.location.href = "/cafe"}>카페테리아로</button>
                </div>
            </div>
        );
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BrowserRouter>
                <ScrollToTop/>
                <Routes>
                    {/* 기본접근 */}
                    {publicRoutes.map(
                        ({ exact, path, component }) => (
                            <Route
                                key={`public-route-${path}`}
                                exact={exact}
                                path={path}
                                element={component}
                            />
                        )
                    )}

                    {/*404*/}
                    <Route
                        key={`public-route-/*`}
                        exact={true}
                        path="/*"
                        element={<PublicOnly/>}
                    />
                </Routes>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

export default Router;